let apiBaseUrl = 'http://localhost:8000/api';
let thaleriaWebsiteUrl = '';
switch (process.env.REACT_APP_ENV) {
  case 'staging':
    apiBaseUrl = 'https://my-staging-api.thaleria.com/api';
    thaleriaWebsiteUrl = 'https://website-staging.thaleria.com';
    break;
  case 'production':
    apiBaseUrl = 'https://my-api.thaleria.com/api';
    thaleriaWebsiteUrl = 'https://www.thaleria.com';
    break;

  default:
    break;
}

export const API_BASE_URL = apiBaseUrl;

export const RECAPTCHA_SITE_KEY = '6LdHO20pAAAAAOwIv9ceTNc_la6oIlBkoSTBortt';

export const THALERIA_WEBSITE_URL = thaleriaWebsiteUrl;


