import vatDisclaimers from '../helpers/vatDisclaimers';
import clientTypes from '../helpers/enum/clientTypes';
import * as countryID from '../helpers/enum/CountryIDs';

const checkVATForClients = (countryId, type) => {
  let rate = 21;
  let disclaimer = null;

  if (type === clientTypes.numbers.EU) {
    disclaimer = vatDisclaimers.EU;
    rate = 0;
  } else if (countryId !== countryID.belgium && type === clientTypes.numbers.Private) {
    disclaimer = vatDisclaimers.foreignPrivate;
    rate = 0;
  }

  return {
    rate,
    disclaimer,
  };
};

export default checkVATForClients;
