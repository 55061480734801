import React, { useState, useEffect, useMemo } from 'react';
import useApi from '../../../../hooks/useApi';
import CreateInvoicingLineForm from '../../../../containers/Finance/InvoicingLines/CreateInvoicingLineForm';
import months from '../../../../helpers/enum/months';
import { endOfMonth, isBefore } from 'date-fns';
import { TimeSheetStatus } from '../../../../helpers/enum/timeSheet';
import * as countryID from '../../../../helpers/enum/CountryIDs';
import PageWrapper from '../../../../containers/App/PageWrapper';
import { showSuccessNotification } from '../../../../store/app/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { lineItemTypesV2 } from '../../../../helpers/enum/lineItemTypes';
import contractTypes from '../../../../helpers/enum/contractTypeIDs';
import formatNumber from '../../../../utils/formatNumber';
import checkVATForClients from '../../../../utils/checkVATForClients';

function CreateInvoicingLine() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userOptions, setUserOptions] = useState([]);
  const [timesheetOptions, setTimesheetOptions] = useState([]);
  const [userPurchaseOrders, setUserPurchaseOrders] = useState([]);
  const [purchaseOrderOptions, setPurchaseOrderOptions] = useState([]);
  const [newInvoicingLine, setNewInvoicingLine] = useState({
    user_id: null,
    staff_order_id: null,
    timesheet_id: null,
    framework_contract_id: null,
    partner_contract_id: null,
    units: 0,
    client_id: null,
    total: 0,
    vat: 0,
    sales_order_id: null,
    price: null,
    specific_contract_id: null,
    vat_rate_client: null,
    accounts_receivable_id: null,
  });
  const [allocationsToAdd, setAllocationsToAdd] = useState([]);
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const { timesheetStart, timesheetEnd } = useMemo(() => {
    let _timesheetStart = null;
    let _timesheetEnd = null;
    if (newInvoicingLine.timesheet_id) {
      _timesheetStart = null;
      _timesheetEnd = null;
    }
    _timesheetStart = new Date(`${newInvoicingLine.year}-${newInvoicingLine.month}-01`);
    _timesheetEnd = endOfMonth(new Date(`${newInvoicingLine.year}-${newInvoicingLine.month}-01`));

    return {
      timesheetStart: _timesheetStart,
      timesheetEnd: _timesheetEnd,
    };
  }, [newInvoicingLine.timesheet_id]);

  const selectedPOBillingUnits = useMemo(() => {
    const selectedOption = purchaseOrderOptions.find(el => el.selected) || null;
    const purchaseOrderWithInfo = userPurchaseOrders.find(el => el.id === selectedOption?.value) || null;
    const billingUnits = {
      purchaseOrder: purchaseOrderWithInfo?.billing_units || null,
      salesOrder: purchaseOrderWithInfo?.salesOrder?.billing_units || null,
    };
    return billingUnits;
  }, [purchaseOrderOptions]);

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-lines', current: false },
    { name: `Invoicing lines`, href: '/admin-panel/finance/invoicing-lines', current: false },
    { name: `Create`, href: '/admin-panel/finance/invoicing-lines/create', current: true },
  ];

  const {
    users: { getAllUsers },
    timesheets: { getAllUserTimesheets, getTimesheetEntries },
    purchaseOrders: { getUserStaffOrders },
    lineItems: { createCustomLineItem },
  } = useApi();

  useEffect(() => {
    getAllUsers().then(res => {
      setUserOptions(
        res.map(el => ({
          value: el.id,
          label: el.full_name,
        })),
      );
    });
  }, []);

  const onChangeUserId = value => {
    getAllUserTimesheets(value).then(res => {
      setTimesheetOptions(
        res
          .filter(
            el =>
              //filter out timesheets that are not pre-approved/approved
              el.status === TimeSheetStatus.APPROVED || el.status === TimeSheetStatus.PREAPPROVE,
          )
          .map(el => ({
            value: el.id,
            label: `${months[el.month - 1]} ${el.year}`,
            month: el.month,
            year: el.year,
          })),
      );
      getUserStaffOrders(value).then(res => {
        setUserPurchaseOrders(res);
      });
    });
    setNewInvoicingLine(prev => ({ user_id: value, timesheet_id: null, staff_order_id: null }));
    setAllocationsToAdd([]);
  };

  const onChangeTimesheetId = value => {
    const timesheet = timesheetOptions.find(ts => ts.value === value);
    const _timesheetStart = new Date(`${timesheet.year}-${timesheet.month}-01`);
    const _timesheetEnd = endOfMonth(new Date(`${timesheet.year}-${timesheet.month}-01`));
    const activePurchaseOrders = [];

    userPurchaseOrders.forEach(po => {
      const poStart = new Date(po.order_start);
      const poEnd = po.order_end ? new Date(po.order_end) : null;
      if (isBefore(poStart, _timesheetEnd) && (!poEnd || isBefore(_timesheetStart, poEnd))) {
        activePurchaseOrders.push({
          value: po.id,
          label: `${po.order_ref} (${po.salesOrder.client.name}, Sales order: ${po.salesOrder.order_ref})`,
          selected: false,
        });
      }
    });

    //if only one active purchase order at the time, automatically select that purchase order, add PO data to newInvoicingLine AND fetch associated timesheet entries
    if (activePurchaseOrders.length === 1) {
      activePurchaseOrders[0].selected = true;
      const po = userPurchaseOrders.find(purchaseOrder => purchaseOrder.id === activePurchaseOrders[0].value);
      const vatRate = checkVATForClients(po.salesOrder.client.country_id, po.salesOrder.client.type).rate;
      setNewInvoicingLine(prev => ({
        ...prev,
        timesheet_id: value,
        date: new Date(`${timesheet.year}/${timesheet.month}/01`),
        year: timesheet.year,
        month: timesheet.month,
        staff_order_id: po.id,
        sales_order_id: po.sales_order_id,
        sales_order_daily_rate: Number(po.salesOrder.daily_rate),
        staff_order_daily_rate: Number(po.daily_rate),
        specific_contract_id: po.specific_contract_id,
        vat_rate_client: vatRate,
        vat_rate_consultant:
          po.staffContract.country_id === countryID.belgium && po.staffContract?.contract_type_id !== contractTypes.employee
            ? 21
            : 0,
        framework_contract_id: po.framework_contract_id,
        partner_contract_id: po.partner_contract_id,
        price: Number(po.salesOrder.daily_rate),
        //FIXME: this vat_rate variable seems deprecated, after searching I did not find it being used anywhere, we are instead using vat_rate_client or vat_rate_consultant
        vat_rate: vatRate,
      }));
      getTimesheetEntries(value).then(res => {
        setTimesheetEntries(res.data.filter(entry => entry.staff_order_id === activePurchaseOrders[0].value));
      });
    } else {
      //If more than one PO, reset the staff_order_id within invoicing item
      setNewInvoicingLine(prev => ({
        ...prev,
        timesheet_id: value,
        staff_order_id: null,
        year: timesheet.year,
        month: timesheet.month,
      }));
    }

    //determine which purchase orders were active at the time of this timesheet and add them to purchase order options
    setPurchaseOrderOptions(activePurchaseOrders.length ? activePurchaseOrders : []);

    setAllocationsToAdd([]);
  };

  const onChangeStaffOrderId = value => {
    //find selected PO, add data to newInvoicingLine AND fetch associated timesheet entries
    setPurchaseOrderOptions(prev =>
      prev.map(el => {
        el.value === value ? (el.selected = true) : (el.selected = false);
        return el;
      }),
    );
    const po = userPurchaseOrders.find(po => po.id === value);
    const vatRate = checkVATForClients(po.salesOrder.client.country_id, po.salesOrder.client.type).rate;
    setNewInvoicingLine(prev => ({
      ...prev,
      staff_order_id: value,
      sales_order_id: po.sales_order_id,
      sales_order_daily_rate: Number(po.salesOrder.daily_rate),
      staff_order_daily_rate: Number(po.daily_rate),
      specific_contract_id: po.specific_contract_id,
      vat_rate_client: vatRate,
      vat_rate_consultant:
        po.staffContract.country_id === countryID.belgium && po.staffContract?.contract_type_id !== contractTypes.employee
          ? 21
          : 0,
      framework_contract_id: po.framework_contract_id,
      partner_contract_id: po.partner_contract_id,
      price: Number(po.salesOrder.daily_rate),
      //FIXME: this vat_rate property seems deprecated, after searching I did not find it being used anywhere, we are instead using vat_rate_client or vat_rate_consultant
      vat_rate: vatRate,
    }));
    getTimesheetEntries(newInvoicingLine.timesheet_id).then(res => {
      setTimesheetEntries(res.data.filter(entry => entry.staff_order_id === value));
    });
  };

  useEffect(() => {
    let revenue = 0;
    let cost = 0;
    let vat_recovered = 0;
    let vat_owed = 0;
    let days_worked = 0;
    let hours_worked = 0;
    allocationsToAdd.forEach(entry => {
      let days = formatNumber(Number(entry.hours) / 8, false, 3);
      days_worked += days;
      hours_worked += entry.hours;
      revenue += days * newInvoicingLine.sales_order_daily_rate;
      cost += days * newInvoicingLine.staff_order_daily_rate;
    });

    vat_recovered = cost * (newInvoicingLine.vat_rate_consultant / 100);
    vat_owed = revenue * (newInvoicingLine.vat_rate_client / 100);

    setNewInvoicingLine(prev => ({
      ...prev,
      revenue,
      cost,
      vat_recovered,
      vat_owed,
      days_worked: formatNumber(days_worked, true, 3),
      hours_worked: hours_worked,
    }));
  }, [allocationsToAdd]);

  const removeEntry = i => {
    setAllocationsToAdd(allocationsToAdd.filter((entry, index) => index !== i));
  };

  const saveCustomLine = (invoicingLine, allocations) => {
    const { timesheet_id, user_id, staff_order_id, ...newInvoicingLine } = invoicingLine;
    const body = {
      invoicingLine: {
        timesheet_id,
        user_id,
        staff_order_id,
      },
      allocations: allocations,
    };
    createCustomLineItem(body).then(res => {
      dispatch(showSuccessNotification('Invoicing line created'));
      history.push(`/admin-panel/finance/invoicing-lines/`);
    });
  };

  return (
    <PageWrapper pages={pages}>
      <CreateInvoicingLineForm
        selectedPOBillingUnits={selectedPOBillingUnits}
        userOptions={userOptions}
        timesheetOptions={timesheetOptions}
        purchaseOrderOptions={purchaseOrderOptions}
        newInvoicingLine={newInvoicingLine}
        timesheetStart={timesheetStart}
        timesheetEnd={timesheetEnd}
        allocationsToAdd={allocationsToAdd}
        setAllocationsToAdd={setAllocationsToAdd}
        removeEntry={removeEntry}
        timesheetEntries={timesheetEntries}
        saveCustomLine={saveCustomLine}
        onChangeUserId={onChangeUserId}
        onChangeTimesheetId={onChangeTimesheetId}
        onChangeStaffOrderId={onChangeStaffOrderId}
      />
    </PageWrapper>
  );
}

export default CreateInvoicingLine;
