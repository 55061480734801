import axios from 'axios';
import { format } from 'date-fns';
import { serialize } from 'object-to-formdata';

function useApi() {
  const announcements = {
    getAllAnnouncement: async (page = 1) => {
      return axios
        .get(`/announcements?page=${page}&size=3`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteAnnouncement: async announcementId => {
      return axios
        .delete(`/announcements/${announcementId}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    createAnnouncement: async (data, file) => {
      const formData = new FormData();

      // Append file if it exists
      if (file) {
        formData.append('file', file);
      }

      // Append title and body
      formData.append('title', data.title);
      formData.append('body', data.body);

      // Append arrays only if they have elements
      ['countries', 'account_types', 'contract_types'].forEach(key => {
        if (Array.isArray(data[key]) && data[key].length > 0) {
          data[key].forEach(value => formData.append(`${key}[]`, value));
        }
      });

      return axios
        .post(`/announcements`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAnnouncement: async announcementId => {
      return axios
        .get(`/announcements/${announcementId}`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateAnnouncement: async (announcementId, data, file) => {
      const formData = new FormData();

      // Append file if it exists
      if (file) {
        formData.append('file', file);
      }

      // Append title and body
      formData.append('title', data.title);
      formData.append('body', data.body);

      // Append arrays only if they have elements
      ['countries', 'account_types', 'contract_types'].forEach(key => {
        if (Array.isArray(data[key]) && data[key].length > 0) {
          data[key].forEach(value => formData.append(`${key}[]`, value));
        }
      });

      return axios
        .put(`/announcements/${announcementId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const reminders = {
    getAllReminders: async () => {
      return axios.get(`/reminders`).then(res => {
        const { data } = res;

        return data;
      });
    },
    getReminder: async reminderId => {
      return axios.get(`/reminders/${reminderId}`).then(res => {
        const { data } = res;

        return data;
      });
    },
    deleteReminder: async reminderId => {
      return axios.delete(`/reminders/${reminderId}`).then(res => {
        const { data } = res;
        return data;
      });
    },
    createReminder: async data => {
      return axios
        .post(`/reminders`, data)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    updateReminder: async (reminderId, data) => {
      return axios.put(`/reminders/${reminderId}`, data).then(res => {
        const { data } = res;

        return data;
      });
    },
  };

  const files = {
    deleteTimesheetFile: async path => {
      return axios.delete(`/files/timesheets`, { data: { key: path } }).catch(err => {
        throw err;
      });
    },

    deleteLeaveRequestFile: async path => {
      return axios.delete(`/files/leave-requests`, { data: { key: path } }).catch(err => {
        throw err;
      });
    },

    downloadTimesheetFile: async path => {
      return axios.get(`/files/timesheets/download/?file=${path}&base64=true`).catch(err => {
        throw err;
      });
    },

    downloadLeaveRequestFile: async path => {
      return axios.get(`/files/leave-requests/download/?file=${path}&base64=true`).catch(err => {
        throw err;
      });
    },

    getFilesOnFolderForTimesheets: async path => {
      return axios.get(`/files/timesheets/?folder=${path}`).catch(err => {
        throw err;
      });
    },

    getAPFilesOnFolderForTimesheet: async timesheetId => {
      return axios.get(`/files/timesheets/accounts-payable/${timesheetId}`).catch(err => {
        throw err;
      });
    },

    getFilesOnFolderForLeaveRequests: async path => {
      return axios.get(`/files/leave-requests/?folder=${path}`).catch(err => {
        throw err;
      });
    },

    //#region Manage Company documents and User's Documents
    uploadDocumentFile: async (file, type, filePath, userId = undefined, restrictions = {}) => {
      var formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);
      formData.append('file_path', filePath);
      if (userId) formData.append('user_id', userId);
      if (restrictions) {
        for (const key of Object.keys(restrictions)) {
          if (key) formData.append(`restrictions[${key}]`, restrictions[key].join('-'));
        }
      }
      return axios
        .post(`/files/company-user-documents/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    //#region Manage Company documents and User's Documents
    uploadMultipleDocumentFiles: async (files, type, filePath, userId = undefined, restrictions = {}) => {
      var formData = new FormData();
      for (const file of files) {
        formData.append('files', file);
      }
      formData.append('type', type);
      formData.append('file_path', filePath);
      if (userId) formData.append('user_id', userId);
      if (restrictions) {
        for (const key of Object.keys(restrictions)) {
          if (key) formData.append(`restrictions[${key}]`, restrictions[key].join('-'));
        }
      }
      return axios
        .post(`/files/company-user-documents/upload-multiple`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    documentsFilesOnFolder: async (type, path) => {
      // type (thaleria-documents | user-document)
      return axios.get(`/files/company-user-documents/${type}?folder_path=${path}`).catch(err => {
        throw err;
      });
    },

    // just for admin to get files of specific user
    userDocumentsFilesOnFolder: async (userId, path) => {
      return axios.get(`/files/company-user-documents/user/${userId}?folder_path=${path}`).catch(err => {
        throw err;
      });
    },

    createFolder: async (type, folderPath, userId = undefined, restrictions) => {
      const reqData = {
        type,
        folder_path: folderPath,
        user_id: userId,
      };

      if (restrictions) {
        reqData.restrictions = restrictions;
      }

      return axios.post(`/files/company-user-documents/folder/`, reqData).catch(err => {
        throw err;
      });
    },

    deleteObject: async (type, objectPath, userId) => {
      const reqData = {
        type,
        object_path: objectPath,
        user_id: userId,
      };

      return axios.post(`/files/company-user-documents/`, reqData).catch(err => {
        throw err;
      });
    },

    downloadDocumentsFiles: async (type, filePath) => {
      return axios.get(`/files/company-user-documents/${type}/download?file_path=${filePath}`).catch(err => {
        throw err;
      });
    },

    // just for admins to download user files
    downloadUserDocumentFiles: async (userId, filePath) => {
      return axios.get(`/files/company-user-documents/user/${userId}/download?file_path=${filePath}`).catch(err => {
        throw err;
      });
    },

    editDocumentAccess: async data => {
      return axios.post(`/files/company-user-documents/edit-access`, data).catch(err => {
        throw err;
      });
    },

    editDocumentName: async data => {
      return axios.post(`/files/company-user-documents/edit-name`, data).catch(err => {
        throw err;
      });
    },

    editTimesheetTags: async (timesheetId, data) => {
      return axios.put(`/files/timesheets/edit-timesheet-tags/${timesheetId}`, data).catch(err => {
        throw err;
      });
    },

    //FINANCE

    getInvoicePDFByID: async id => {
      return axios.get(`/files/finance/invoices/${id}`).catch(err => {
        throw err;
      });
    },
    getCreditNotePDFByID: async id => {
      return axios.get(`/files/finance/credit-notes/${id}`).catch(err => {
        throw err;
      });
    },

    //ANNOUNCEMENTS

    downloadAnnouncementBanner: async (announcementId, thumbnail) => {
      let queryString = `announcement_id=${announcementId}&thumbnail=${thumbnail ? true : false}`;

      return axios
        .get(`/files/announcements/download?${queryString}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const notifications = {
    getLatestNotifications: async () => {
      return axios
        .get('/notifications/my/latest')
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    updateNotification: async (id, data) => {
      return await axios
        .patch(`/notifications/my/edit/${id}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    getAllMyNotifications: async page => {
      return axios
        .get(`/notifications/my/all?page=${page}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    allNotificationsToRead: async () => {
      return axios
        .patch(`/notifications/my/read-all`)
        .then(res => {
          return true;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const holidays = {
    getAllHolidaysPaginated: async (
      page,
      name,
      countries,
      startDate,
      endDate,
      types,
      clientTypes,
      clients,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ) => {
      let params = {
        page,
        name,
        countries: countries?.join(','),
        startDate,
        endDate,
        types: types?.join(','),
        //need to map the array to change null value into 'null' string
        //otherwise null value will be joined as an empty space ('') and won't be readable in backend
        clientTypes: clientTypes?.join(','),
        clients: clients?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv,
      };
      return axios
        .get(`/holidays/all-paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getHolidayOptions: async () => {
      return axios
        .get(`/holidays/options`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getMyHolidaysForTimesheets: async (startDate, endDate) => {
      return axios.get(`/holidays/timesheets/my`, { params: { start_date: startDate, end_date: endDate } }).catch(err => {
        throw err;
      });
    },

    getHolidaysForTimesheets: async (startDate, endDate, userId) => {
      return axios
        .get(`/holidays/timesheets/user/${userId}`, { params: { start_date: startDate, end_date: endDate } })
        .catch(err => {
          throw err;
        });
    },

    getMyHolidaysForLR: async (startDate, endDate) => {
      return axios.get(`/holidays/LR/my`, { params: { start_date: startDate, end_date: endDate } }).catch(err => {
        throw err;
      });
    },

    getHolidaysForLR: async (startDate, endDate, userId) => {
      return axios.get(`/holidays/LR/user/${userId}`, { params: { start_date: startDate, end_date: endDate } }).catch(err => {
        throw err;
      });
    },

    getHolidayByID: async id => {
      return axios.get(`/holidays/${id}`).catch(err => {
        throw err;
      });
    },

    createHolidays: async data => {
      return axios
        .post(`/holidays`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateHoliday: async (id, data) => {
      return axios
        .put(`/holidays/${id}`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteHoliday: async holidayId => {
      return axios.delete(`/holidays/${holidayId}`).catch(err => {
        throw err;
      });
    },
  };

  const salesOrders = {
    getSalesOrderRefs: async () => {
      return axios
        .get(`/sales-orders/get-all-refs`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getSalesOrderOptions: async () => {
      return axios
        .get(`/sales-orders/all`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getPaginatedSalesOrders: async ({
      page,
      reference,
      startDates,
      endDates,
      client,
      invoicingType,
      rate,
      value,
      daysOrdered,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    }) => {
      const params = {
        page,
        reference,
        startDates: startDates?.join(','),
        endDates: endDates?.join(','),
        client,
        invoicingType,
        daysOrdered,
        rate,
        value,
        sortBy,
        globalFilter,
        downloadToCsv,
      };
      return axios
        .get(`/sales-orders/paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getFilterOptions: async () => {
      return axios
        .get('/sales-orders/filter-options')
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getSalesOrderById: async id => {
      return axios
        .get(`/sales-orders/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    fetchSalesOrderLogs: async orderId => {
      return await axios.get(`/sales-orders/remaining-days-log`, { params: { orderId } }).catch(err => {
        throw err;
      });
    },

    updateSOMilestoneStatus: async (id, data) => {
      return await axios.put(`/sales-orders/milestone/${id}`, data).catch(err => {
        throw err;
      });
    },
  };

  const userTypes = {
    getAllUserTypes: async () => {
      return axios
        .get(`/user-types`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const contractTypes = {
    getAllContractTypes: async () => {
      return axios
        .get(`/staff-contracts/contract-types`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const countries = {
    getAllCountries: async () => {
      return axios
        .get(`/countries`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getCountriesUsedInSC: async () => {
      return axios
        .get(`/countries/used-in-sc`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllCountriesPaginated: async (page, name, sortBy, globalFilter, downloadToCsv, source) => {
      let params = { page, name, sortBy, globalFilter, downloadToCsv };
      return axios
        .get(`/countries/paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    createCountry: async data => {
      return axios
        .post(`/countries`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateCountry: async (id, data) => {
      return axios
        .put(`/countries/${id}`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteCountry: async countryId => {
      return axios.delete(`/countries/${countryId}`).catch(err => {
        throw err;
      });
    },
  };

  const pointOfContacts = {
    getAllContacts: async () => {
      return axios
        .get(`/point-of-contacts`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllContactsPaginated: async (
      page,
      firstName,
      lastName,
      email,
      phoneNb,
      clients,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ) => {
      let params = {
        page,
        firstName,
        lastName,
        email,
        phoneNb,
        clients: clients?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv,
      };

      return axios
        .get(`/point-of-contacts/paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getClientOptions: async () => {
      return axios
        .get(`/point-of-contacts/client-options`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    createContact: async data => {
      return axios
        .post(`/point-of-contacts`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateContact: async (id, data) => {
      return axios
        .put(`/point-of-contacts/${id}`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteContact: async id => {
      return axios.delete(`/point-of-contacts/${id}`).catch(err => {
        throw err;
      });
    },
  };

  const clients = {
    getAllClients: async () => {
      return axios
        .get(`/clients/all`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllClientsPaginated: async (page, name, type, countries, description, sortBy, globalFilter, downloadToCsv, source) => {
      let params = {
        page,
        name,
        type: type?.join(','),
        countries: countries?.join(','),
        description,
        sortBy,
        globalFilter,
        downloadToCsv,
      };
      return axios
        .get(`/clients/all-paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getClientOptions: async () => {
      return axios
        .get(`/clients/options`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getClientsByType: async type => {
      return await axios
        .get(`/clients?type=${type}`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    createClient: async data => {
      return axios
        .post(`/clients`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateClient: async (id, data) => {
      return axios
        .put(`/clients/${id}`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteClient: async id => {
      return axios.delete(`/clients/${id}`).catch(err => {
        throw err;
      });
    },
  };

  const staffContracts = {
    getContractDetails: async id => {
      return axios
        .get(`/staff-contracts/get-details/${id}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getPaginatedStaffContracts: async (
      page,
      users,
      contractTypes,
      countries,
      calendarCountries,
      contractRef,
      position,
      startDates,
      endDates,
      vatNb,
      bicSwift,
      iban,
      companyName,
      companyAddress,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ) => {
      const params = {
        page,
        users: users?.join(','),
        contractTypes: contractTypes?.join(','),
        countries: countries?.join(','),
        calendarCountries: calendarCountries?.join(','),
        contractRef,
        position,
        startDates: startDates?.join(','),
        endDates: endDates?.join(','),
        vatNb,
        bicSwift,
        iban,
        companyName,
        companyAddress,
        sortBy,
        globalFilter,
        downloadToCsv: downloadToCsv,
      };
      return await axios
        .get(`/staff-contracts/paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getStaffContractOptions: async () => {
      return await axios
        .get(`/staff-contracts/options`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getStaffContractById: async id => {
      return axios
        .get(`/staff-contracts/get-by-id/${id}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    createStaffContract: async data => {
      return axios
        .post(`/staff-contracts`, data)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    editStaffContract: async (id, data) => {
      return axios
        .put(`/staff-contracts/${id}`, data)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getActiveStaffContracts: async (userId, startDate, endDate) => {
      let params = {};

      if (startDate || endDate) {
        params = {
          startDate,
          endDate,
        };
      }
      return axios
        .get(`/staff-contracts/get-active/${userId}`, { params })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getUserStaffContracts: async userId => {
      return axios
        .get(`/staff-contracts/user-contracts`, { params: { userId } })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteContract: async contractId => {
      return axios.delete(`/staff-contracts/${contractId}`).catch(err => {
        throw err;
      });
    },

    fetchAssociatedPOs: async contractId => {
      return axios
        .get(`/staff-contracts/associated-purchase-orders/${contractId}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    fetchEstimatedLeaveDayBalance: async data => {
      return axios
        .get(
          `/staff-contracts/estimate-leave-day-balance?country_id=${data.country_id}&contract_start=${data.contract_start}&contract_end=${data.contract_end}&hours_per_week=${data.hours_per_week}`,
        )
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const specificContracts = {
    getSpecificContractRefs: async () => {
      return axios
        .get(`/specific-contracts/get-all-refs`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getSpecificContractOptions: async () => {
      return axios
        .get(`/specific-contracts/all`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getSpecificContractById: async id => {
      return axios
        .get(`/specific-contracts/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    fetchSpecificContractsLogs: async contractId => {
      return await axios.get(`/specific-contracts/remaining-days-log`, { params: { contractId } }).catch(err => {
        throw err;
      });
    },

    updateSCMilestoneStatus: async (id, data) => {
      return await axios.put(`/specific-contracts/milestone/${id}`, data).catch(err => {
        throw err;
      });
    },
  };

  const frameworkContracts = {
    getFrameworkContractRefs: async () => {
      return axios
        .get(`/framework-contracts/get-all-refs`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllFrameworkContracts: async page => {
      return axios
        .get(`/framework-contracts`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllFrameworkContractsPaginated: async (
      page,
      reference,
      startDates,
      endDates,
      clients,
      types,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ) => {
      const params = {
        page,
        reference,
        startDates: startDates?.join(','),
        endDates: endDates?.join(','),
        clients: clients?.join(','),
        types: types?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv: downloadToCsv,
      };
      return axios
        .get(`/framework-contracts/paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getFrameworkContractOptions: async () => {
      return axios
        .get(`/framework-contracts/options`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    createFrameworkContract: async data => {
      return axios
        .post(`/framework-contracts`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateFrameworkContract: async (id, data) => {
      return axios
        .patch(`/framework-contracts/${id}`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteContract: async contractId => {
      return axios.delete(`/framework-contracts/${contractId}`).catch(err => {
        throw err;
      });
    },

    getClientFrameworkContracts: async clientId => {
      return axios
        .get(`/framework-contracts/client/${clientId}`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getFrameworkContractDescription: async idArray => {
      const params = { ids: idArray.join(',') };

      if (!idArray.length) return [''];
      return axios
        .get('/framework-contracts/get-description', { params })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const timesheets = {
    getAllMyTimesheets: async () => {
      return axios
        .get(`/timesheets/my`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllTimeSheets: async () => {
      return axios
        .get('/timesheets/admin')
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getPreviousAndNext: async (userId, month, year) => {
      const params = {
        userId: userId,
        month: month,
        year: year,
      };
      return axios
        .get(`/timesheets/previous-and-next`, { params })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllUserTimesheets: async userId => {
      return axios
        .get(`/timesheets/user-timesheets/${userId}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllTimeSheetsPaginated: async (
      page,
      id,
      startDate,
      endDate,
      users,
      status,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
      bulkDownload,
    ) => {
      let params = {
        page,
        id,
        startDate,
        endDate,
        users: users?.join(','),
        status: status?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv,
        bulkDownload,
      };
      return axios
        .get('/timesheets/admin-paginated', { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getFilterOptions: async () => {
      return axios
        .get('/timesheets/filter-options')
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getMyTimeSheet: async timeSheetId => {
      return axios
        .get(`/timesheets/my/${timeSheetId}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getTimesheet: async timeSheetId => {
      return axios
        .get(`/timesheets/admin/${timeSheetId}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    createTimesheet: async data => {
      return axios
        .post(`/timesheets`, data)
        .then(r => r)
        .catch(err => {
          throw err;
        });
    },

    deleteTimesheet: async id => {
      return axios
        .delete(`/timesheets/${id}`)
        .then(res => {
          return res;
        })
        .catch(err => {
          throw err;
        });
    },

    updateTimesheetStatus: async data => {
      return axios.put(`/timesheets/status`, data).catch(err => {
        throw err;
      });
    },

    saveTimesheetEntries: async data => {
      return axios.post(`/timesheet-entries`, data).catch(err => {
        throw err;
      });
    },

    getMyTimesheetEntries: async timesheetId => {
      return axios.get(`/timesheet-entries/my/timesheet/${timesheetId}`).catch(err => {
        throw err;
      });
    },

    getTimesheetEntries: async timesheetId => {
      return axios.get(`/timesheet-entries/timesheet/${timesheetId}`).catch(err => {
        throw err;
      });
    },

    deleteTimesheetEntries: async ids => {
      return axios.post('/timesheet-entries/delete', { ids }).catch(err => {
        throw err;
      });
    },

    getAllComments: async id => {
      return axios.get(`/timesheet-comments/${id}`).catch(err => {
        throw err;
      });
    },

    addComment: async data => {
      return axios.post(`/timesheet-comments`, data).catch(err => {
        throw err;
      });
    },

    uploadTimesheetFile: async (file, timesheetId, type, tags) => {
      let params = {};
      if (type) params.type = type;
      if (Object.keys(tags).length) {
        params.tags = tags;
      }
      var formData = new FormData();
      formData.append('file', file);
      if (tags) {
        if (tags?.comment) formData.append('comment', tags?.comment);
        if (tags?.invoiceMatches) formData.append('invoiceMatches', tags?.invoiceMatches);
      }

      return axios
        .post(`/files/timesheets/upload/${timesheetId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params,
        })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    fetchTimesheetEntriesForLog: async (id, type, page, size, users, status, fromDate, toDate) => {
      return await axios
        .get(`/timesheet-entries/timesheet-entries-log/${id}`, { params: { type, status, users, fromDate, toDate, page, size } })
        .catch(err => {
          throw err;
        });
    },

    getTimesheetTotals: async timesheetId => {
      return await axios
        .get(`/timesheet-entries/get-timesheet-totals/${timesheetId}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    calculateDaysRemainingUntilDate: async (staffOrderId, date) => {
      const params = {
        staffOrderId: staffOrderId,
        date: date,
      };
      return await axios
        .get(`/timesheet-entries/calculate-days-remaining`, { params })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    bulkDownloadTS: async (arrayOfIds, fileNameStructure, source) => {
      const params = {
        ids: arrayOfIds?.length && arrayOfIds.join(','),
        structure: JSON.stringify(fileNameStructure),
      };
      return await axios
        .get('/timesheets/bulk-download', { params, responseType: 'arraybuffer', cancelToken: source?.token })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/zip' });

          // Create a blob URL and initiate a download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'timesheets.zip'; // Specify the desired file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch(e => {
          throw e;
        });
    },
  };

  const leaveRequests = {
    getAvailableLeave: async () => {
      return axios
        .get(`/leave-day-balance-log/my/available-leave`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    createLeaveRequestByAdmin: async (userId, data) => {
      return axios
        .post(`/leave-requests/user/${userId}`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getUserAvailableLeave: async (userId, staffContractId) => {
      return axios
        .get(`/leave-day-balance-log/available-leave/${userId}/${staffContractId}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getChangeLogs: async page => {
      return axios
        .get(`/leave-day-balance-log/my/change-logs${`?page=` + page}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getUserChangeLogs: async (userId, staffContractId, page) => {
      return axios
        .get(`/leave-day-balance-log/change-logs/${userId}/${staffContractId}${`?page=` + page}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    getAllLeaveRequest: async () => {
      return axios
        .get(`/leave-requests`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllLeaveRequestsPaginated: async (
      page,
      id,
      type,
      startDate,
      endDate,
      requestedDays,
      users,
      status,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ) => {
      const params = {
        page,
        id,
        type: type?.join(','),
        startDate: startDate?.join(','),
        endDate: endDate?.join(','),
        requestedDays: requestedDays?.join(','),
        users: users?.join(','),
        status: status?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv,
      };
      return axios
        .get(`/leave-requests/paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getFilterOptions: async () => {
      return axios
        .get(`/leave-requests/filter-options`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllMyLeaveRequests: async (startDate, endDate, statuses) => {
      return axios
        .get(`/leave-requests/my`, { params: { statuses, start_date: startDate, end_date: endDate } })
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllUserActiveLeaveRequests: async (userId, startDate, endDate) => {
      return axios
        .get(`/leave-requests/user-active/${userId}`, { params: { start_date: startDate, end_date: endDate } })
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getLeaveRequest: async id => {
      return axios
        .get(`/leave-requests/${id}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    getMyLeaveRequest: async id => {
      return axios
        .get(`/leave-requests/my/${id}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateLeaveRequestByAdmin: async (leaveRequestId, body) => {
      return axios.put(`/leave-requests/${leaveRequestId}`, body).catch(err => {
        throw err;
      });
    },

    updateLeaveRequestStatusByAdmin: async (leaveRequestId, body) => {
      return axios.put(`/leave-requests/status/${leaveRequestId}`, body).catch(err => {
        throw err;
      });
    },

    updateMyLeaveRequest: async (leaveRequestId, body) => {
      return axios.put(`/leave-requests/my/${leaveRequestId}`, body).catch(err => {
        throw err;
      });
    },

    submitMyLeaveRequest: async leaveRequestId => {
      return axios.put(`/leave-requests/my/submit/${leaveRequestId}`).catch(err => {
        throw err;
      });
    },

    deleteMyLeaveRequest: async (leaveRequestId, body) => {
      return axios.put(`/leave-requests/my/cancel-or-delete/${leaveRequestId}`, body).catch(err => {
        throw err;
      });
    },

    createLeaveRequest: async data => {
      return axios
        .post(`/leave-requests/my`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    CreateAdminComment: async (id, body) =>
      axios.put(`/leave-request-comments/${id}`, { body }).catch(err => {
        throw err;
      }),

    createUserComment: async (id, body) =>
      axios
        .put(`/leave-request-comments/my/${id}`, { body })
        .then(res => {})
        .catch(err => {
          throw err;
        }),

    updateLeaveRequestBalance: async (userId, body) => {
      return axios
        .put(`/leave-day-balance-log/${userId}`, body)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    uploadFile: async (file, leaveRequestId) => {
      var formData = new FormData();
      formData.append('file', file);

      return await axios
        .post(`/files/leave-requests/upload/${leaveRequestId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const users = {
    getAllUsers: async () => {
      return axios
        .get(`/users/all`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    getUsersWithActiveContracts: async () => {
      return axios
        .get(`/users/active-contracts`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    getStaffAndAdminUsers: async () => {
      return axios
        .get(`/users/staff-or-admin`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    getAllDeletedUsers: async () => {
      return axios
        .get(`/users/all/deleted`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    getUser: async userId => {
      return axios
        .get(`/users/${userId}`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    updateUserInfo: async updatedUserInfo => {
      const formData = serialize(updatedUserInfo);
      return axios
        .put(`/users`, formData)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    createUser: async newUserInfo => {
      const formData = serialize(newUserInfo, { nullsAsUndefineds: true });
      return await axios
        .post(`/users`, formData)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    deleteUser: async userId => {
      return axios.delete(`/users/${userId}`).catch(err => {
        throw err;
      });
    },
    undoDeleteUser: async userId => {
      const data = { user_id: userId };
      return axios
        .put('/users/undo-delete', data)
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    activateUser: async userId => {
      const data = { user_id: userId };
      return axios.put('/users/active', data).catch(err => {
        throw err;
      });
    },
    deActiveUser: async userId => {
      return axios.delete(`/users/deactive/${userId}`).catch(err => {
        throw err;
      });
    },
    resetPassword: async data => {
      return axios
        .post('/users/reset-password', data)
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    updatePassword: async data => {
      return axios
        .patch('/users/my/update-password', data)
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    removeTemporaryPassword: async data => {
      return axios
        .patch('/users/my/temp-password', data)
        .then(response => {
          const { data } = response;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    forgotPassword: async data => {
      return axios
        .patch('/users/forgot-password', data)
        .then(res => {
          return res;
        })
        .catch(err => {
          return err;
        });
    },
    deActiveUser: async userId => {
      return axios
        .delete(`/users/deactive/${userId}`)
        .then(response => {
          return axios.get(`/users/all`).then(res => {
            const { data } = res;
            return data;
          });
        })
        .catch(err => {
          throw err;
        });
    },
    deleteUsersProfilePicture: async id => {
      return axios
        .delete(`/users/user-picture/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          return err;
        });
    },
  };

  const purchaseOrders = {
    getActiveStaffOrders: async userId => {
      return await axios
        .get(`/staff-orders/get-active/${userId}`)
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    getStaffOrderRefs: async () => {
      return axios
        .get(`/staff-orders/get-all-refs`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    getUserStaffOrders: async userId => {
      return await axios
        .get(`/staff-orders/user-staff-orders`, { params: { userId } })
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    getOrdersForTimesheet: async userId => {
      const params = {};
      if (userId) params.userId = userId;
      return await axios
        .get('/staff-orders/for-timesheet', { params })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    createAssociatedContracts: async data => {
      return axios
        .post(`/staff-orders/create-associated-contracts`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateAssociatedContracts: async (staffOrderId, data) => {
      return axios
        .put(`/staff-orders/update-associated-contracts/${staffOrderId}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateOrderExtension: async (orderId, data) => {
      return axios
        .put(`/staff-orders/contract-extension/${orderId}`, data)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },

    fetchStaffOrdersForDashboardPaginated: async (page, filters, sortBy, globalFilter, downloadToCsv, source) => {
      let params = { page, sortBy, globalFilter, downloadToCsv };
      if (filters?.filterContractsUpForExtension)
        Object.assign(params, { upForExtension: JSON.stringify(filters?.filterContractsUpForExtension) });
      if (filters?.consultant) Object.assign(params, { consultantFilters: JSON.stringify(filters?.consultant) });
      if (filters?.purchaseOrder) Object.assign(params, { purchaseOrderFilters: JSON.stringify(filters?.purchaseOrder) });
      if (filters?.salesOrder) Object.assign(params, { salesOrderFilters: JSON.stringify(filters?.salesOrder) });
      if (filters?.specificContract)
        Object.assign(params, { specificContractFilters: JSON.stringify(filters?.specificContract) });
      if (filters?.staffContract) Object.assign(params, { staffContractFilters: JSON.stringify(filters?.staffContract) });
      if (filters?.endClientFwc) Object.assign(params, { endClientFwcFilters: JSON.stringify(filters?.endClientFwc) });
      if (filters?.salesFwc) Object.assign(params, { salesFwcFilters: JSON.stringify(filters?.salesFwc) });

      return axios
        .get(`/staff-orders/paginated`, { params, cancelToken: source?.token })
        .then(res => {
          const { data } = res;
          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    getFilterOptions: async () => {
      return axios
        .get(`/staff-orders/filter-options`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    fetchStaffOrder: async id => {
      return axios
        .get(`/staff-orders/${id}`)
        .then(res => {
          const { data } = res;

          return data;
        })
        .catch(err => {
          throw err;
        });
    },
    deleteOrder: async orderId => {
      return axios.delete(`/staff-orders/${orderId}`).catch(err => {
        throw err;
      });
    },

    fetchStaffOrderLogs: async orderId => {
      return await axios.get(`/staff-orders/remaining-days-log`, { params: { orderId } }).catch(err => {
        throw err;
      });
    },

    getStaffOrderAssociations: async id => {
      return await axios.get(`/staff-orders/associations/${id}`).catch(err => {
        throw err;
      });
    },

    getAssociatedContractIds: async id => {
      return await axios
        .get(`/staff-orders/contract-type-ids/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updatePOMilestoneStatus: async (id, data) => {
      return await axios.put(`/staff-orders/milestone/${id}`, data).catch(err => {
        throw err;
      });
    },
  };

  const tasks = {
    getTaskLog: async (page, closed, module, tags, source) => {
      return await axios
        .get(`/tasks`, { params: { page, closed, module, tags }, cancelToken: source?.token || undefined })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    getTaskThread: async (threadId, page) => {
      return await axios
        .get(`/tasks/thread/${threadId}`, { params: { page } })
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
    getTaskDetails: async taskId => {
      return await axios
        .get('/tasks/details/' + taskId)
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
    addComment: async (comment, taskId) => {
      return await axios
        .post(`tasks/comment/${taskId}`, { comment })
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
    addTag: async (taskId, userId) => {
      return await axios
        .post(`tasks/tag/${taskId}/${userId}`)
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
    removeTag: async tagId => {
      return await axios
        .delete(`tasks/tag/${tagId}`)
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
    toggleClosed: async taskId => {
      return await axios
        .patch(`tasks/close/${taskId}`)
        .then(res => res.data)
        .catch(err => {
          throw err;
        });
    },
  };

  const bugReport = {
    sendBugReport: async (subject, description, documents) => {
      var formData = new FormData();
      if (documents?.length) {
        documents.forEach(doc => formData.append('documents', doc.rawFile));
      }
      formData.append('subject', subject);
      formData.append('description', description);
      return await axios
        .post('/bug-report', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const adminDashboard = {
    getDashboardData: async () => {
      return await axios
        .get('/users/admin-dashboard')
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const comments = {
    deleteComment: async url => {
      return await axios
        .delete(url)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const accountsReceivables = {
    getAccountsReceivables: async (
      page,
      invoiceNumbers,
      clients,
      revenue,
      vatOwed,
      grandTotal,
      sent,
      paid,
      createdBy,
      issueDate,
      dueDate,
      overdue,
      paidAmount,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
      bulkDownload,
    ) => {
      let params = {
        page,
        invoiceNumbers: invoiceNumbers?.join(','),
        clients: clients?.join(','),
        revenue: revenue?.join(','),
        vatOwed: vatOwed?.join(','),
        grandTotal: grandTotal?.join(','),
        sent: sent?.join(','),
        paid: paid?.join(','),
        createdBy: createdBy?.join(','),
        issueDate: issueDate?.join(','),
        dueDate: dueDate?.join(','),
        overdue: overdue?.join(','),
        paidAmount: paidAmount?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv,
        bulkDownload,
      };
      return await axios
        .get(`/accounts-receivables/all-paginated`, { params, cancelToken: source?.token })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAccountsReceivableByInvoiceNb: async id => {
      return await axios
        .get(`/accounts-receivables/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAccountReceivableIds: async () => {
      return await axios
        .get(`/accounts-receivables/ids`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAccountsReceivableFilterOptions: async () => {
      return await axios
        .get(`/accounts-receivables/options`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    createAccountsReceivable: async (data, source) => {
      return await axios
        .post(`/accounts-receivables/`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateAccountsReceivable: async (id, data) => {
      return await axios
        .put(`/accounts-receivables/${id}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updatePaidOrSentStatus: async (id, data) => {
      return await axios
        .put(`/accounts-receivables/paid-or-sent/${id}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteAccountsReceivable: async id => {
      return await axios
        .delete(`/accounts-receivables/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getLatestInvoiceNumber: async () => {
      return await axios
        .get(`/accounts-receivables/latest-invoice-number`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getContractAssociations: async params => {
      return await axios
        .get('/accounts-receivables/get-associated-contracts', { params })
        .then(res => res.data)
        .catch(e => {
          throw e;
        });
    },

    bulkDownloadAR: async (arrayOfIds, fileNameStructure, source) => {
      const { includeTS, ...fileNameStructureWithoutIncludeTS } = fileNameStructure;
      const params = { arrayOfIds: arrayOfIds, structure: JSON.stringify(fileNameStructureWithoutIncludeTS), includeTS };
      return await axios
        .get('/accounts-receivables/bulk-download', {
          params,
          responseType: 'arraybuffer',
          cancelToken: source?.token,
        })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/zip' });
          // Create a blob URL and initiate a download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'accounts-receivable.zip'; // Specify the desired file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch(e => {
          throw e;
        });
    },

    getOverdueKPI: async query => {
      let params = {
        applyFilters: query?.applyFilters,
        invoiceNumbers: query?.invoiceNumbers?.join(','),
        clients: query?.clients?.join(','),
        revenue: query?.revenue?.join(','),
        vatOwed: query?.vatOwed?.join(','),
        grandTotal: query?.grandTotal?.join(','),
        sent: query?.sent?.join(','),
        createdBy: query?.createdBy?.join(','),
        issueDate: query?.issueDate?.join(','),
        paidAmount: query?.paidAmount?.join(','),
        sortBy: query?.sortBy,
        globalFilter: query?.globalFilter,
      };

      return await axios
        .get('/accounts-receivables/overdueKPI', { params })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getInvoiceFileName: async (invoiceNb, fileNameStructure) => {
      const params = { invoiceNb: invoiceNb, structure: fileNameStructure };

      return await axios
        .get(`/accounts-receivables/file-name`, { params })
        .then(res => {
          return res;
        })
        .catch(err => {
          throw err;
        });
    },

    bulkPayment: async (arrayOfIds, paid, payment_date, sent) => {
      const params = { arrayOfIds, paid, payment_date, sent };
      return await axios
        .put('/accounts-receivables/bulk-payment', params)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const accountsPayable = {
    getAccountsPayable: async (
      page,
      paid,
      users,
      dueDate,
      cost,
      vat,
      costAndVat,
      approved,
      paidAmount,
      iban,
      bicSwift,
      companyName,
      amountMatch,
      createdAt,
      customLines,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
      bulkDownload,
    ) => {
      const params = {
        page,
        paid,
        users: users?.join(','),
        dueDate: dueDate?.join(','),
        cost: cost?.join(','),
        paidAmount: paidAmount?.join(','),
        vat: vat?.join(','),
        costAndVat: costAndVat?.join(','),
        approved,
        iban,
        bicSwift,
        companyName,
        amountMatch,
        customLines,
        createdAt: createdAt?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv,
        bulkDownload,
      };
      return await axios
        .get(`/accounts-payables/all-paginated`, { params, cancelToken: source?.token })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAccountsPayableOptions: async (paid, approved) => {
      const params = {
        paid,
        approved,
      };
      return await axios
        .get(`/accounts-payables/options`, { params })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAccountsPayableById: async id => {
      return await axios
        .get(`/accounts-payables/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    editAccountsPayable: async (acctsPayableId, data, file) => {
      var formData = new FormData();
      if (file) {
        formData.append('file', file);
      }
      // Append each field from the data object
      Object.keys(data).forEach(key => {
        const value = data[key];
        if (key === 'due_date') {
          formData.append(key, value.toISOString()); // Convert due_date to ISO string
        } else if (key === 'lineItemsToAdd') {
          // Append lineItemsToAdd array
          value.forEach((item, index) => {
            if ('id' in item) {
              // If item has 'id' key, append the id
              formData.append(`lineItemsToAdd[${index}][id]`, item.id);

              // Check if metadata exists and is an object
              if (item.metadata && typeof item.metadata === 'object') {
                // Append each property of metadata
                Object.keys(item.metadata).forEach(metaKey => {
                  formData.append(`lineItemsToAdd[${index}][metadata][${metaKey}]`, item.metadata[metaKey]);
                });
              }
            } else {
              // Otherwise, append each property normally
              Object.keys(item).forEach(prop => {
                formData.append(`lineItemsToAdd[${index}][${prop}]`, item[prop]);
              });
            }
          });
        } else {
          // Append other fields normally
          formData.append(key, value);
        }
      });
      return await axios
        .put(`/accounts-payables/${acctsPayableId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updatePaidStatus: async (acctsPayableId, data) => {
      return await axios
        .put(`/accounts-payables/paid/${acctsPayableId}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateApprovedStatus: async (acctsPayableId, data) => {
      return await axios
        .put(`/accounts-payables/approved/${acctsPayableId}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    bulkDownloadAP: async (arrayOfIds, source) => {
      const params = {
        ids: arrayOfIds?.length && arrayOfIds.join(','),
      };
      return await axios
        .get('/accounts-payables/bulk-download', { params, responseType: 'arraybuffer', cancelToken: source?.token })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/zip' });

          // Create a blob URL and initiate a download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'accounts-payable.zip'; // Specify the desired file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch(e => {
          throw e;
        });
    },

    getUnpaidKPI: async query => {
      const params = {
        applyFilters: query.applyFilters,
        users: query.users?.join(','),
        period: query.period
          ?.map(d => {
            if (d instanceof Date) {
              return format(d, 'yyyy-MM-dd');
            }
            return null;
          })
          ?.join(','),
        approved: query.approved,
        status: query.status?.join(','),
        cost: query.cost?.join(','),
        vat: query.vat?.join(','),
        costAndVat: query.costAndVat?.join(','),
        sortBy: query.sortBy,
        globalFilter: query.globalFilter,
      };
      return await axios
        .get('/accounts-payables/unpaidkpi', { params })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getMyInvoices: async () => {
      return await axios
        .get('/accounts-payables/my/invoices')
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getMyInvoice: async id => {
      return await axios
        .get(`/accounts-payables/my/invoice/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    createAPComment: async data => {
      return await axios
        .post(`/accounts-payable-comments/`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    createAccountsPayable: async (data, file) => {
      var formData = new FormData();
      formData.append('file', file);
      // Append each field from the data object
      Object.keys(data).forEach(key => {
        const value = data[key];
        if (key === 'due_date') {
          formData.append(key, value.toISOString()); // Convert due_date to ISO string
        } else if (key === 'lineItemsToAdd') {
          // Append lineItemsToAdd array
          value.forEach((item, index) => {
            if ('id' in item) {
              // If item has 'id' key, append the id
              formData.append(`lineItemsToAdd[${index}][id]`, item.id);

              // Check if metadata exists and is an object
              if (item.metadata && typeof item.metadata === 'object') {
                // Append each property of metadata
                Object.keys(item.metadata).forEach(metaKey => {
                  formData.append(`lineItemsToAdd[${index}][metadata][${metaKey}]`, item.metadata[metaKey]);
                });
              }
            } else {
              // Otherwise, append each property normally
              Object.keys(item).forEach(prop => {
                formData.append(`lineItemsToAdd[${index}][${prop}]`, item[prop]);
              });
            }
          });
        } else {
          // Append other fields normally
          formData.append(key, value);
        }
      });

      return axios
        .post(`/accounts-payables/create`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteAccountsPayable: async id => {
      return axios
        .delete(`/accounts-payables/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAccountsPayableFilesOnFolder: async id => {
      return axios.get(`/files/finance/accounts-payable/${id}`).catch(err => {
        throw err;
      });
    },

    downloadAPFile: async id => {
      return axios.get(`/files/finance/accounts-payable/download/${id}`).catch(err => {
        throw err;
      });
    },

    previewAPFile: async id => {
      return axios.get(`/files/finance/accounts-payable/preview/${id}`).catch(err => {
        throw err;
      });
    },

    autoApproveAPs: async () => {
      return axios.put(`/accounts-payables/auto-approve`).catch(err => {
        throw err;
      });
    },

    bulkPayment: async (arrayOfIds, paid, payment_date) => {
      const params = { arrayOfIds, paid, payment_date };
      return await axios
        .put('/accounts-payables/bulk-payment', params)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const settings = {
    fetchTableSettings: async () => {
      return await axios
        .get(`/settings?setting=table_settings`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateTableSettings: async data => {
      let json = JSON.stringify(data);
      let body = { settingsData: { table_settings: json } };
      return await axios
        .put(`/settings/table`, body)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    fetchFileNameSettings: async () => {
      return await axios
        .get(`/settings?setting=file_name_settings`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateFileNameSettings: async data => {
      let json = JSON.stringify(data);
      let body = { settingsData: { file_name_settings: json } };
      return await axios
        .put(`/settings/file-name`, body)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    resetSettings: async () => {
      return await axios
        .delete(`/settings/reset`)
        .then(res => {
          return res;
        })
        .catch(err => {
          throw err;
        });
    },
  };
  const bankAccounts = {
    getBankAccountsPaginated: async (page, sortBy, globalFilter, downloadToCsv, source) => {
      const params = {
        page,
        sortBy,
        globalFilter,
        downloadToCsv,
      };

      return await axios
        .get(`/bank-accounts/all-paginated`, { params, cancelToken: source?.token })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getBankAccountById: async id => {
      return await axios
        .get(`/bank-accounts/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllBankAccounts: async () => {
      return await axios
        .get(`/bank-accounts/all`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    createBankAccount: async data => {
      return await axios
        .post(`/bank-accounts`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    editBankAccount: async (id, data) => {
      return await axios
        .put(`/bank-accounts/${id}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteBankAccount: async id => {
      return await axios
        .delete(`/bank-accounts/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const creditNotes = {
    createCreditNote: async (data, source) => {
      return await axios
        .post(`/credit-notes/`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAllCreditNotesPaginated: async (
      page,
      creditNoteNumbers,
      invoiceNumbers,
      clients,
      amount,
      vat,
      amountAndVat,
      sent,
      createdBy,
      issueDate,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
      bulkDownload,
    ) => {
      const params = {
        page,
        creditNoteNumbers: creditNoteNumbers?.join(','),
        invoiceNumbers: invoiceNumbers?.join(','),
        clients: clients?.join(','),
        amount: amount?.join(','),
        vat: vat?.join(','),
        amountAndVat: amountAndVat?.join(','),
        sent: sent?.join(','),
        createdBy: createdBy?.join(','),
        issueDate: issueDate?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv,
        bulkDownload,
      };
      return await axios
        .get(`/credit-notes/all-paginated`, { params, cancelToken: source?.token })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getLatestCreditNoteNumber: async () => {
      return await axios
        .get(`/credit-notes/latest-credit-note-number`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getCreditNoteById: async id => {
      return await axios
        .get(`/credit-notes/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    updateCreditNoteSentStatus: async (id, data) => {
      return await axios
        .put(`/credit-notes/sent/${id}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    editCreditNote: async (id, data) => {
      return await axios
        .patch(`/credit-notes/${id}`, data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteCreditNote: async id => {
      return await axios
        .delete(`/credit-notes/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getCreditNoteFilterOptions: async () => {
      return await axios
        .get(`/credit-notes/options`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteCreditNote: async id => {
      return await axios
        .delete(`/credit-notes/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    bulkDownloadCN: async (arrayOfIds, fileNameStructure, source) => {
      const params = {
        ids: arrayOfIds,
        structure: JSON.stringify(fileNameStructure),
      };
      return await axios
        .get('/credit-notes/bulk-download', { params, responseType: 'arraybuffer', cancelToken: source?.token })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/zip' });
          // Create a blob URL and initiate a download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'credit-notes.zip'; // Specify the desired file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch(e => {
          throw e;
        });
    },

    getCreditNoteFileName: async (creditNoteNb, fileNameStructure) => {
      const params = { creditNoteNb: creditNoteNb, structure: fileNameStructure };

      return await axios
        .get(`/credit-notes/file-name`, { params })
        .then(res => {
          return res;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const lineItems = {
    getUnassignedLineItems: async userId => {
      return await axios
        .get(`/line-items/unassigned/${userId}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    deleteAPAdjustmentLineItem: async id => {
      return await axios
        .delete(`/line-items/ap-adjustment/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
    getInvoicingLinesPaginated: async (
      page,
      users,
      dates,
      purchaseOrderRef,
      salesOrderRef,
      specificContractRef,
      clients,
      units,
      price,
      total,
      invoiced,
      partnerContract,
      vat,
      totalAndVat,
      accountsReceivableIds,
      invoiceNumbers,
      timesheetStatus,
      type,
      uom,
      approved,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ) => {
      let params = {
        page,
        users: users?.join(','),
        purchaseOrders: purchaseOrderRef?.join(','),
        salesOrders: salesOrderRef?.join(','),
        dates: dates?.map(d => d).join(','),
        clients: clients?.join(','),
        units: units?.join(','),
        price: price?.join(','),
        total: total?.join(','),
        invoiced,
        vat: vat?.join(','),
        totalAndVat: totalAndVat?.join(','),
        partnerContracts: partnerContract?.join(','),
        specificContracts: specificContractRef?.join(','),
        accountsReceivable: accountsReceivableIds?.join(','),
        invoices: invoiceNumbers?.join(','),
        timesheetStatus: timesheetStatus?.join(','),
        type: type?.join(','),
        uom: uom,
        approved: approved,
        sortBy,
        globalFilter,
        downloadToCsv,
      };

      return await axios
        .get('/line-items/invoicing-lines/all-paginated', { params, cancelToken: source?.token })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getLineItemsByIds: async ids => {
      const params = {
        ids: ids?.join(','),
      };
      return await axios
        .get(`/line-items/get-by-ids`, { params })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getCounterparts: async ids => {
      const params = {
        ids: ids?.join(','),
      };
      return await axios
        .get(`/line-items/get-counterparts`, { params })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    createCustomLineItem: async body => {
      return await axios
        .post(`/line-items/`, body)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getInvoicingLinesByStaffOrderId: async (staffOrderId, accountsReceivableId) => {
      //only returns non-invoiced items
      return await axios
        .get(`/line-items/invoicing-lines/get-by-staff-order/${staffOrderId}/${accountsReceivableId}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getInvoicingLineOptions: async (invoiced, approved, source) => {
      const params = {
        invoiced,
        approved,
      };
      return await axios
        .get(`/line-items/invoicing-lines/options`, { params, cancelToken: source?.token })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteInvoicingLineById: async id => {
      return await axios
        .delete(`/line-items/${id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getAvailableAPLines: async userId => {
      return await axios
        .get(`/line-items/available-ap-lines/${userId}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const system = {
    sanitizeData: async data => {
      return await axios
        .post('/system/sanitize-data', data)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  const leaveDaysBalanceLog = {
    getLeaveDaysLogPaginated: async (
      page,
      users,
      creators,
      dates,
      comment,
      recuperationDays,
      leaveDays,
      staffContracts,
      leaveRequests,
      types,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ) => {
      let params = {
        page,
        users: users?.join(','),
        creators: creators?.join(','),
        dates: dates?.map(d => d)?.join(','),
        comment: comment,
        recuperationDays: recuperationDays,
        leaveDays: leaveDays,
        staffContracts: staffContracts?.join(','),
        leaveRequests: leaveRequests?.join(','),
        types: types?.join(','),
        sortBy,
        globalFilter,
        downloadToCsv,
      };

      return await axios
        .get('/leave-day-balance-log/all', { params, cancelToken: source?.token })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },

    getFilterOptions: async () => {
      return await axios
        .get('/leave-day-balance-log/filter-options')
        .then(res => {
          return res.data;
        })
        .catch(err => {
          throw err;
        });
    },
  };

  return {
    announcements,
    reminders,
    files,
    notifications,
    holidays,
    salesOrders,
    userTypes,
    contractTypes,
    countries,
    pointOfContacts,
    clients,
    staffContracts,
    specificContracts,
    frameworkContracts,
    timesheets,
    leaveRequests,
    users,
    purchaseOrders,
    tasks,
    bugReport,
    adminDashboard,
    comments,
    accountsReceivables,
    accountsPayable,
    creditNotes,
    settings,
    bankAccounts,
    lineItems,
    system,
    leaveDaysBalanceLog,
  };
}

export default useApi;
